import React, {
    FC,
}                           from "react";
import _	                from 'lodash';

import {
    forumResult_topics,
    forumDiscussionResult,
}                           from "../../../../types/graphqlTypes";

const forumsGql = require('../../../../gql/forums.gql');

import { Discussion } from "./Discussion";

import { ForumSortOrder } from "../model/ForumSortOrder";

interface IDiscussionsProps {
    forumId: string,
    topic: forumResult_topics,
    discussionResults: forumDiscussionResult[],
    sortOrder: any,
    discussionsFilter: any,
    showTopic: boolean,
    onSelectTopic: (topicResult: forumResult_topics) => void,
    onSelectDiscussion: (discussionResult: forumDiscussionResult, topicResult: forumResult_topics) => void,
}

export const Discussions : FC<IDiscussionsProps> = (props: IDiscussionsProps) => {
    let sortedDiscussions = props.discussionResults ? [...props.discussionResults].sort(
        function(a, b) {
            if (props.sortOrder == ForumSortOrder.Title) {
                let x = a.headPost.subject.toLowerCase();
                let y = b.headPost.subject.toLowerCase();
                if (x < y) {return -1;}
                if (x > y) {return 1;}
            } else {
                if(a.lastActivityDate > b.lastActivityDate) { return -1; }
                if(a.lastActivityDate < b.lastActivityDate) { return 1; }
            }
            return 0;
        }
    ) : null;

    return (
        <div className="discussion-topic">
            {
            sortedDiscussions?.map(
                (discussionResult, index) => 
                    //ADFFN-9322 - Added additional Unread filter prop when grabbing discussions
                    ((discussionResult.discussion.isOpen && props.discussionsFilter.open) ||
                        (!discussionResult.discussion.isOpen && props.discussionsFilter.closed)) && 
                    ((discussionResult.hasNewMarker && props.discussionsFilter.unread) || !props.discussionsFilter.unread) &&    
                    <Discussion key={discussionResult.discussion.discussionId} discussionResult={discussionResult} 
                        onSelectTopic={props.onSelectTopic} topicResult={props.topic} onSelectDiscussion={props.onSelectDiscussion}
                        showTopic={props.showTopic} />
                ) 
            }
        </div>
    )
}
