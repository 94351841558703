import React, {
    FC,
} from "react";
import _ from 'lodash';

interface FilterProps {
    discussionsFilter: any,
    onChangeTopicFilter: (open: boolean, closed: boolean, unread: boolean) => void,
}

export const Filter: FC<FilterProps> = (props: FilterProps) => {
    var showOpen = props.discussionsFilter.open;
    var showClosed = props.discussionsFilter.closed;
    var showUnread = props.discussionsFilter.unread;

    const toggleOpen = (e) => {
        showOpen = !showOpen;
        props.onChangeTopicFilter(showOpen, showClosed, showUnread);
    }

    const toggleClosed = (e) => {
        showClosed = !showClosed;
        props.onChangeTopicFilter(showOpen, showClosed, showUnread);
    }

    const toggleUnread = (e) => {
        showUnread = !showUnread;
        props.onChangeTopicFilter(showOpen, showClosed, showUnread);
    }

    return (
        <div>
            <div className="checkbox-inline checked">
                <input type="checkbox" onClick={toggleOpen} name="open" id="open" defaultChecked={props.discussionsFilter.open} />
                <label htmlFor="open">Open</label>
            </div>
            <div className="checkbox-inline">
                <input type="checkbox" onClick={toggleClosed} name="closed" id="closed" defaultChecked={props.discussionsFilter.closed} />
                <label htmlFor="closed">Closed</label>
            </div>
            <div className="checkbox-inline">
                <input type="checkbox" onClick={toggleUnread} name="unread" id="unread" defaultChecked={props.discussionsFilter.unread} />
                <label htmlFor="unread">Unread Only</label>
            </div>
        </div>
    )
}

export default Filter;
