import React, {
    FC,
    useState,
} from "react";
import _ from 'lodash';

import ForumTabs from "./ForumTabs";
import {
    forumDiscussionResult,
    forumResult_topics,
} from "../../../../types/graphqlTypes";

import { Discussions } from "./Discussions";

import { TopicSortOrder } from "../model/TopicSortOrder";
import { Sort } from "./Sort";
import { Filter } from "./Filter";

interface ITopicProps {
    discussionResults: forumDiscussionResult[],
    topicResult: forumResult_topics,
    sortOrder: typeof TopicSortOrder,
    onSelectTopic: (topicResult: forumResult_topics) => void,
    onSelectDiscussion: (discussionResult: forumDiscussionResult, topicResult: forumResult_topics) => void,
    forumId: string,
    onCancel: () => void,
    newDiscussion: () => void,
    onChangeSortOrder: (e: any) => void,
}

export const ViewTopic: FC<ITopicProps> = (props: ITopicProps) => {
    const [showDiscussions, setShowDiscussions] = useState(true);
    const [discussionsFilter, setDiscussionsFilter] = useState({
        open: true,
        closed: false,
        unread: false
    });
    const threadsClass = "threads" + (showDiscussions ? "" : " closed");

    const onChangeTopicFilter = (open: boolean, closed: boolean, unread: boolean) => {
        setDiscussionsFilter({
            open: open,
            closed: closed,
            unread: unread
        });
    }

    return (
        <div>
            <ForumTabs newDiscussion={props.newDiscussion} postToDiscussion={false} title={props.topicResult?.topic.title} />

            <div className="discussion-topic">
                <div className="back">
                    <a onClick={props.onCancel}>&lt; Discussion home</a>
                </div>

                <div className="actions">
                    <Filter onChangeTopicFilter={onChangeTopicFilter} discussionsFilter={discussionsFilter} />
                    <Sort sortList={TopicSortOrder} onChangeSortOrder={props.onChangeSortOrder} sortOrder={props.sortOrder} topicsEnabled={false} />
                </div>

                <div className={threadsClass}>
                    <Discussions onSelectDiscussion={props.onSelectDiscussion} forumId={props.forumId}
                        discussionsFilter={discussionsFilter} discussionResults={props.discussionResults}
                        topic={props.topicResult} sortOrder={props.sortOrder} showTopic={false}
                        onSelectTopic={props.onSelectTopic} />
                </div>
            </div>
        </div>
    )
}
